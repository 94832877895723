import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/layouts/post-layout.js"
import CustomImage from '$components/CustomImage'
import Carousel from '$components/Carousel'
import DataProvider from '$components/DataProvider'
import Vimeo from '$components/Article/Vimeo'
import { graphql } from 'gatsby'

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>



<MDXTag name="h1" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`This project is different from everything I did before. I aimed to create something unique, something that ignores the principles of human-centered design and is purely build upon the content it conveys.
No grid, no conventions, no Roboto.
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://book-of-shaders.ludwigfrank.com/"}}>{`See the final product.`}</MDXTag></MDXTag>
<MDXTag name="h1" components={components}>{`Outcome`}</MDXTag>
<Vimeo
    src="https://player.vimeo.com/video/311080051?autoplay=1&loop=1"
    width={1056}
    height={574}
/>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`» The youngest kids tried to squeeze themselves through the legs of the crowd of adults, attempting to get a seat where they could see her. Wrangling for the frontmost place on the floor, looking up from time to time, to the woman sitting on a fur against the wall of the bunker. Everyone wanted to make sure they still preserved a decent distance between them and her. A few hours ago a group of colony hunters found her outside. No one knew how she could have survived the radiation, but the lumps covering her body made clear her story must be truthful: she experienced the world before the apocalypse. When the strange spheres she described as Motus could be controlled and represented the resource of energy for all of humanity… «`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`The Book of Shaders`}</MDXTag>
<MDXTag name="p" components={components}>{`I admire the power of shaders, so little code to generate complex generative artworks – it amazed me from the first second.
The website `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://thebookofshaders.com/"}}>{`"The Book of Shaders"`}</MDXTag>{` helped me a lot in learning this language. I enjoyed the little interactive blocks the site integrates to let the user grasp the topic and learn one crucial thing about shaders: it is all about trial and error in the beginning.`}</MDXTag>
<MDXTag name="p" components={components}>{`Like learning any new language, repetition is vital. I created a medium that embraces this fact. One that would help others in staying committed. To learn repeatedly, by taking part in a compelling story. Each week another chapter will continue said story, with new challenges, pieces of training and a ton of shader magic to stay motivated.`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  